import * as React from "react"
import Layout from '../components/Layout'
import PriceComponent from '../components/Table'

const PricePage = () => {
  return(
     <div>
      <Layout title="Sumadhura Folium | Sumadhura Folium Cost">
      <div style={{marginTop:"12vh", textAlign:"center"}}>
          <PriceComponent/>
        </div>
      </Layout>
     </div>
  )
}

export default PricePage
